<template>
  <div class="new-detail">
         <div class="w title_detail">{{newsInfo.wzbt}}</div>
    <div class="w time_detail">{{newsInfo.createtime}}</div>
    <div class="w content_detail" v-html="newsInfo.wznr">{{newsInfo.wznr}}</div>
  </div>
</template>

<script>
import {getOneNews} from './model'
export default {
    data(){
        return {
            newsInfo:''
        }
    },
    created(){
    getOneNews({owid:this.$route.query.owid}).then(res=>{
       this.newsInfo = res;
     })
    }
}
</script>
